import React from "react";

const ProjectOverflow = ({ number }: { number: number }) => {
  return (
    <div className="z-20 h-6 w-6">
      <svg width="100%" height="100%" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="rgb(0,0,0,0.5)"
          d="M27.542,5.497L19.483,0.907C18.479,0.337 17.35,0.025 16.197,0L16.015,0C14.79,-0.011 13.584,0.302 12.517,0.907L4.458,5.497C3.413,6.087 2.542,6.945 1.934,7.984C1.327,9.022 1.004,10.204 1,11.409L1,20.589C1.004,21.794 1.327,22.976 1.934,24.014C2.542,25.052 3.413,25.91 4.458,26.5L12.517,31.093C13.521,31.662 14.65,31.974 15.803,32L15.985,32C17.21,32.011 18.417,31.698 19.483,31.093L27.542,26.503C28.587,25.913 29.458,25.055 30.066,24.017C30.673,22.978 30.996,21.796 31,20.592L31,11.409C30.995,10.204 30.673,9.022 30.065,7.984C29.458,6.945 28.587,6.087 27.542,5.497Z"
        />
        <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fill="white" style={{ fontSize: "16px" }}>
          +{number}
        </text>
      </svg>
    </div>
  );
};

export default ProjectOverflow;
